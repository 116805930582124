<template>
  <div>
    <global-loader v-if="isLoading && !hasError" />
    <global-error
      v-if="!isLoading && hasError"
      :showLogo="componentIsEnabled('globalError', 'logo')"
      :code="errorCode"
      :theme="theme"
    />
    <div
      v-if="!isLoading && !hasError"
      class="el-centered fade-in"
    >
      <div
        class="el-stripe"
        style="margin: auto"
      >
        <div class="el-bounds _narrow">
          <div class="el-centered__main">
            <div class="el-centered__main_aside">
              <div class="el-section eu-align_center eu-align_left_md">
                <a
                  href="javascript:void(0)"
                  @click="goToSignin"
                >
                  <emd-logo></emd-logo>
                </a>
              </div>
              <div class="eu-display_none eu-display_block_md">
                <div class="el-chunk">
                  <h1 class="emd-text _size_page _color_primary">
                    {{ $t('CONTENT.TITLE') }}
                  </h1>
                </div>
                <p class="emd-text _size_section _color_default">
                  <strong>{{ $t('CONTENT.SUBTITLE') }}</strong>
                </p>
              </div>
            </div>
            <!-- Login -->
            <div class="el-centered__main_body">
              <div class="el-section">
                <router-view></router-view>
              </div>
              <!-- Footer -->
              <emd-footer></emd-footer>
              <!-- ./Footer -->
            </div>
            <!-- /Login -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmdFooter from '@/components/emerald/organisms/EmdFooter.vue'
import GlobalLoader from '@/components/project/globals/GlobalLoader.vue'
import { GlobalError } from 'emerald-vue/src/components'
import EmdLogo from '@/components/project/globals/EmdLogo.vue'

export default {
  name: 'SignTemplate',
  components: {
    EmdFooter,
    GlobalError,
    GlobalLoader,
    EmdLogo
  },
  computed: {
    isLoading () {
      return this.$store.state.globalLoading
    },
    hasError () {
      return this.$store.state.globalError
    },
    pref () {
      return this.$store.getters.pref
    },
    errorCode () {
      return this.$store.getters.getGlobalErrorCode
    },
    theme () {
      return process.env.VUE_APP_THEME
    }
  },
  mounted () {
    const body = document.body
    const preferences = Object.assign({}, this.pref)
    if (['pagarme'].includes(process.env.VUE_APP_THEME)) {
      this.$store.dispatch('FORCE_LIGHT_MODE')
      preferences.appearance.mode = 'light'
    }
    if (preferences.appearance.mode === 'light') {
      body.classList.remove('_mode_dark')
    } else {
      body.classList.add('_mode_dark')
    }
    this.$store.dispatch('SET_PREFERENCES', preferences)
  },
  methods: {
    goToSignin () {
      this.$router.push({ name: 'signin' })
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    }
  }
}
</script>
<style>
.emd-list svg {
  display: inline-block;
  vertical-align: top;
}
.el-centered__main_aside .emd-list .emd-text {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  width: 85%;
}
</style>
