import IDCore from 'id-js'
import ProfileCore from 'profile-js'
import UtilitiesCore from 'utilities-js'

export const dataCore = {
  environment: process.env.VUE_APP_CORE_ENV,
  theme: process.env.VUE_APP_THEME
}
export const IDInstance = new IDCore(dataCore)
export const UtilitiesInstance = new UtilitiesCore(dataCore)
export const ProfileInstance = new ProfileCore(dataCore)
export const env = UtilitiesInstance.envAcronym
export * from './formValidate'
export * from './formatErrorMessage'
export * from './toggleThemeMode'

export const encodeToStorage = value => window.btoa(JSON.stringify(value))
export const decodeToStorage = value => JSON.parse(window.atob(value))
