import { log as logFS } from '@fullstory/browser'
import { version } from '@/../package.json'

const application = {
  state: {
    globalLoading: false,
    globalError: false,
    maintenance: false,
    globalErrorCode: '',
    appVersion: version
  },
  getters: {
    appVersion (state) {
      return state.appVersion
    },
    getGlobalErrorCode (state) {
      return {
        errorCode: state.globalErrorCode,
        hasError: state.globalError
      }
    },
    maintenance (state) {
      return state.maintenance
    }
  },
  mutations: {
    setGlobalError (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
      if (status && code !== 404 && code !== 403)
        logFS('error', 'Error: redirected to screen 500')
    },
    setGlobalLoading (state, loaderState) {
      state.globalLoading = loaderState
    }
  },
  actions: {
    UPDATE_GLOBAL_LOADING ({ commit }, loaderState) {
      commit('setGlobalLoading', loaderState)
    },
    UPDATE_GLOBAL_ERROR ({ commit }, { status, code }) {
      commit('setGlobalError', { status, code })
    }
  }
}

export default application
