<template>
  <div class="el-section el-centered eu-align-items_center">
    <emd-pagarme-loader
      class="fade-in"
      :color="'secondary'"
    />
  </div>
</template>
<script>
import { EmdPagarmeLoader } from 'emerald-vue/src/components'

export default {
  name: 'GlobalLoader',
  components: {
    EmdPagarmeLoader
  }
}
</script>
