import Vue from 'vue'
import Vuex from 'vuex'
import {
  IDInstance,
  ProfileInstance,
  env,
  encodeToStorage,
  decodeToStorage
} from '@/support'
import Cookies from 'js-cookie'

import { roles, whitelabel, application, captcha } from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    roles,
    whitelabel,
    application,
    captcha
  },
  state: {
    domain: IDInstance.domain,
    user: {
      email: '',
      token: sessionStorage.getItem(`${env}mp_userRoles`)
        ? {
            roles: decodeToStorage(sessionStorage.getItem(`${env}mp_userRoles`))
          }
        : undefined
    },
    feedbackType: '',
    validated: {
      value: {},
      type: undefined
    },
    pref: ProfileInstance.me.getPreferences(),
    showTermsOfUseModal: false
  },
  mutations: {
    SET_USER_EMAIL (state, email) {
      state.user.email = email
    },
    SET_FEEDBACK_TYPE (state, payload) {
      state.feedbackType = payload
    },
    SET_VALIDATED (state, payload) {
      state.validated = payload
    },
    SET_PREF (state, pref) {
      state.pref = Object.assign(state.pref, pref)
    },
    SET_TERMS_OF_USE_MODAL (state) {
      state.showTermsOfUseModal = !state.showTermsOfUseModal
    },
    setUserToken (state, token) {
      state.user.token = token
      sessionStorage.setItem(`${env}mp_userRoles`, encodeToStorage(token.roles))
    }
  },
  getters: {
    token: () => {
      return IDInstance.one.isLoggedIn()
    },
    GET_FEEDBACK_TYPE (state) {
      return state.feedbackType
    },
    GET_VALIDATED (state) {
      return state.validated
    },
    pref (state) {
      return state.pref
    },
    userEmail (state) {
      return state.user.email
    },
    showTermsOfUseModal (state) {
      return state.showTermsOfUseModal
    },
    userRoles (state) {
      return state.user.token?.roles.map(role => role.toLowerCase()) || []
    }
  },
  actions: {
    VALIDATE_EMAIL ({ commit }, email) {
      commit('SET_USER_EMAIL', email)
    },
    SET_USER_EMAIL ({ commit }, email) {
      commit('SET_USER_EMAIL', email)
    },
    CREATE_AUTHORIZATION ({ commit }, userData) {
      return IDInstance.one.createToken(userData).then(res => {
        commit('setUserToken', res)
        return Promise.resolve(res)
      })
    },
    CREATE_REGISTER ({ commit }, userData) {
      return IDInstance.operation.register(userData)
    },
    RESET_PASSWORD (context, email) {
      return IDInstance.one.resetPassword(email)
    },
    DELETE_TOKENS () {
      return IDInstance.one.deleteToken()
    },
    SET_PASSWORD (context, content) {
      return IDInstance.one.confirmPasswordReset(content)
    },
    UPDATE_FEEDBACK_TYPE ({ commit }, payload) {
      commit('SET_FEEDBACK_TYPE', payload)
    },
    VALIDATE_TOKEN ({ commit }, passwordInfo) {
      return IDInstance.one.checkPasswordReset(passwordInfo).then(res => {
        commit('SET_VALIDATED', { ...res, ...passwordInfo })
        return Promise.resolve(res)
      })
    },
    CREATE_USER (context, body) {
      return IDInstance.user.create(body)
    },
    GET_PREFERENCES (context) {
      const pref = Cookies.get(`${env}mp_pref`)
        ? JSON.parse(
            atob(Cookies.get(`${env}mp_pref`), { domain: context.state.domain })
          )
        : {}
      context.commit('SET_PREF', pref)
      return pref
    },
    SET_LANGUAGE ({ dispatch, state }, lang) {
      const preferences = Object.assign({}, state.pref)
      preferences.language = lang
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_THEME_MODE ({ dispatch, state }, mode) {
      const preferences = Object.assign({}, state.pref)
      preferences.appearance.mode = mode
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_PREFERENCES (context, pref) {
      try {
        const preferences = btoa(JSON.stringify(pref))
        preferences.appearance.theme = process.env.VUE_APP_THEME
        Cookies.set(`${env}mp_pref`, preferences, {
          domain: context.state.domain
        })
        context.commit('SET_PREF', pref)
        return true
      } catch (err) {
        return err
      }
    },
    FORCE_LIGHT_MODE ({ state, dispatch }) {
      const preferences = Object.assign({}, state.pref)
      preferences.appearance.mode = 'light'
      dispatch('SET_PREFERENCES', preferences)
    },
    GO_TO_REDIRECT_URL () {
      IDInstance.one.goToUrl(process.env, store.getters.userRoles)
    },
    SET_TERMS_OF_USE_MODAL (context) {
      context.commit('SET_TERMS_OF_USE_MODAL')
    }
  }
})

export default store
