export function formatErrorMessage (error, defaultError) {
  let errors = []
  var arrayOfArrays = []
  var errorBodyExists = error && error.body && Object.keys(error.body).length

  if (
    errorBodyExists &&
    error.body.errors &&
    Object.keys(error.body.errors).length
  ) {
    arrayOfArrays = Object.values(error.body.errors)
    errors = error.body.errors.map(errorMessages => errorMessages.message)
  } else if (
    errorBodyExists &&
    error.body.notifications &&
    Object.keys(error.body.notifications).length
  ) {
    arrayOfArrays = Object.values(error.body.notifications)
    errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
  } else if (
    errorBodyExists &&
    error.body.message &&
    Object.keys(error.body.message).length
  ) {
    errors = [defaultError]
  } else if (errorBodyExists) {
    arrayOfArrays = Object.values(error.body)
    errors = arrayOfArrays.reduce((a, b) => [...a, ...b], [])
  } else {
    errors = [defaultError]
  }
  return errors
}
