import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import SignTemplate from '@/components/project/templates/SignTemplate.vue'
import Signin from '@/views/Signin.vue'
// import Signup from '@/views/Signup.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '',
      component: SignTemplate,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          next()
        }
      },
      children: [
        {
          path: '',
          redirect: 'signin'
        },
        {
          path: 'feedback',
          name: 'feedback',
          component: () =>
            import(/* webpackChunkName: "feedback" */ '@/views/Feedback.vue')
        },
        {
          path: 'reset-password',
          name: 'resetpassword',
          component: () =>
            import(
              /* webpackChunkName: "resetpassword" */ '@/views/ResetPassword.vue'
            )
        },
        {
          path: 'user',
          name: 'validate',
          component: () =>
            import(/* webpackChunkName: "validate" */ '@/views/Validate.vue')
        }
      ]
    },
    {
      path: '/users/:users/passwordresets/*',
      name: 'validatedUser',
      component: () =>
        import(
          /* webpackChunkName: "validated" */ '@/components/project/templates/ValidateTemplate.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          next()
        }
      },
      children: [
        {
          path: 'set-password',
          name: 'setpassword',
          component: () =>
            import(
              /* webpackChunkName: "setpassword" */ '@/views/Validated/SetPassword.vue'
            )
        }
      ]
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next()
        } else {
          next({ name: '' })
        }
      }
    },
    {
      path: '/signin',
      name: 'signin',
      component: Signin,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) next({ name: 'maintenance' })
        else next()
      }
    },
    {
      path: '*',
      redirect: 'signin'
    }
  ]
})
