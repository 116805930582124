<template>
  <main class="el-main">
    <div class="el-main__content">
      <div class="el-centered eu-align-items_center">
        <div class="eu-flex eu-justify_center">
          <div class="eu-flex-item eu-basis_12 eu-px_3">
            <div class="el-chunk">
              <div class="el-section">
                <div class="el-chunk">
                  <div class="emd-text _align_center">
                    <emd-pagarme-logo-green />
                  </div>
                </div>
                <div class="el-chunk">
                  <p class="emd-text _size_small eu-align_center eu-mb_3">
                    {{ $t('LOGIN.UNIFICATION-MESSAGE.1') }}
                    <strong>{{ $t('LOGIN.UNIFICATION-MESSAGE.2') }}</strong>
                    {{ $t('LOGIN.UNIFICATION-MESSAGE.3') }}
                    <a
                      :href="urlFaq"
                      class="emd-text _color_primary"
                      >{{ $t('LOGIN.UNIFICATION-MESSAGE.4') }}</a
                    >
                    {{ $t('LOGIN.UNIFICATION-MESSAGE.5') }}
                  </p>
                </div>
                <div class="el-chunk">
                  <div class="eu-flex eu-justify_center">
                    <div
                      id="signin-box-login"
                      class="emd-container"
                    >
                      <div class="emd-container__content">
                        <div class="el-bounds _narrow">
                          <div class="el-section">
                            <h2
                              class="emd-text _type_caption _align_center el-chunk"
                            >
                              {{ $t('LOGIN.LOGIN-INFORMATION.1') }} <br />
                              {{ $t('LOGIN.LOGIN-INFORMATION.2') }}
                            </h2>
                          </div>

                          <form
                            ref="loginForm"
                            @submit="validateEmail"
                            name="emailForm"
                            novalidate="true"
                          >
                            <fieldset :disabled="loginFormIsLoading">
                              <emd-alert
                                variant="danger"
                                v-if="loginFormError"
                                @cancel="loginFormError = false"
                                :title="''"
                              >
                                <template slot="content">
                                  <p
                                    v-for="(error, index) in errors"
                                    :key="index"
                                  >
                                    {{ error }}
                                  </p>
                                </template>
                              </emd-alert>

                              <!-- Email -->
                              <emd-form-group
                                :state="loginFormError ? 'error' : ''"
                                :label="$t('LOGIN.EMAIL-LABEL')"
                                labelFor="email"
                              >
                                <emd-input
                                  name="email"
                                  type="email"
                                  :placeholder="$t('LOGIN.PLACEHOLDER')"
                                  required
                                  v-model="email"
                                  @input="emailIsValid"
                                  ref="emailInput"
                                  autoFocus
                                />
                              </emd-form-group>

                              <!-- Password -->
                              <emd-form-group
                                :state="passwordFormError ? 'error' : ''"
                                :label="$t('PASSWORD.LABEL')"
                                labelFor="password"
                                :feedbacks="[
                                  {
                                    msg: $t('PASSWORD.ERROR-TEXT'),
                                    condition: passwordValidityState.tooShort
                                  }
                                ]"
                              >
                                <div class="emd-input-group">
                                  <emd-input
                                    name="password"
                                    class="fs-exclude"
                                    type="password"
                                    placeholder="Digite sua senha"
                                    ref="passwordInput"
                                    required
                                    v-model="password"
                                    :onInput="passwordIsValid"
                                  />
                                  <a
                                    class="emd-input-icon__link _align_right emd-link _color_primary"
                                    href="javascript:void(0)"
                                    tabindex="-1"
                                    @click="
                                      showPassword($refs.passwordInput.$el)
                                    "
                                  >
                                    <i
                                      class="uil uil-eye-slash"
                                      v-if="passwordIsText"
                                    ></i>
                                    <i
                                      class="uil uil-eye"
                                      v-else
                                    ></i>
                                  </a>
                                </div>
                              </emd-form-group>

                              <div
                                v-if="showCaptcha"
                                class="eu-align_center el-chunk"
                              >
                                <p class="emd-input-feedback eu-mb_2">
                                  {{ $t('PASSWORD.CAPTCHA') }}
                                </p>
                                <vue-recaptcha
                                  :sitekey="this.sitekey"
                                  :loadRecaptchaScript="true"
                                  @verify="validateCaptcha"
                                  @expired="resetCaptcha"
                                  :language="$i18n.locale"
                                  :theme="pref.appearance.mode"
                                  class="captcha-container g-recaptcha eu-flex eu-justify_center"
                                />
                                <p
                                  class="emd-input-feedback eu-align_center _state_error"
                                  v-if="showCaptchaFeedback"
                                >
                                  {{ $t('PASSWORD.REQUIRED-CAPTCHA') }}
                                </p>
                              </div>

                              <button
                                type="submit"
                                class="emd-btn _color_primary _block eu-mb_3"
                                tabindex="0"
                                :disabled="disableButton"
                              >
                                <emd-loader
                                  v-if="loginFormIsLoading"
                                ></emd-loader>
                                <span v-else>
                                  {{ $t('PASSWORD.BUTTON-TEXT') }}</span
                                >
                              </button>

                              <router-link
                                class="emd-btn _color_secondary _type_text _block eu-mb_3"
                                :to="{ name: 'resetpassword' }"
                                tabindex="-1"
                                >{{ $t('PASSWORD.FORGOT-LINK') }}</router-link
                              >
                            </fieldset>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import store from '@/store'
import { formatErrorMessage } from '@/support/formatErrorMessage'
import {
  EmdLoader,
  EmdAlert,
  EmdInput,
  EmdFormGroup,
  EmdPagarmeLogoGreen
} from 'emerald-vue/src/components'
import VueRecaptcha from 'vue-recaptcha'

export default {
  name: 'Signin',
  components: {
    EmdAlert,
    EmdLoader,
    EmdInput,
    EmdFormGroup,
    EmdPagarmeLogoGreen,
    VueRecaptcha
  },
  data () {
    return {
      loginFormIsLoading: false,
      loginFormError: false,
      emailFormIsValid: false,
      errors: [],
      email: undefined,
      password: undefined,
      passwordFormError: false,
      passwordFormIsValid: false,
      passwordValidityState: {
        valid: true,
        tooShort: false
      },
      passwordIsText: false,
      sitekey: '6LcH6-wUAAAAAObNxBeKH9hZspRcz-_v9Qj5hq7k',
      recaptcha: null,
      showCaptchaFeedback: false,
      validatedCaptcha: false
    }
  },
  computed: {
    urlFaq () {
      return process.env.VUE_APP_URL_FAQ
    },
    urlLoginPagarme () {
      return process.env.VUE_APP_URL_PAGARME
    },
    showCaptcha () {
      return this.$store.getters.showCaptcha
    },
    pref () {
      return this.$store.getters.pref
    },
    totalUserFail () {
      return this.$store.getters.totalUserFail
    },
    disableButton () {
      return (
        (!this.emailFormIsValid && !this.passwordFormIsValid) ||
        (this.showCaptcha && !this.validatedCaptcha)
      )
    }
  },
  beforeRouteEnter (to, from, next) {
    const token = store.getters.token
    if (token) {
      store.dispatch('UPDATE_GLOBAL_LOADING', false)
      store.dispatch('GO_TO_REDIRECT_URL')
    } else {
      next()
      store.dispatch('UPDATE_GLOBAL_LOADING', false)
      next()
    }
  },
  methods: {
    validateEmail (e) {
      e.preventDefault()
      this.errors = []
      this.loginFormIsLoading = true
      this.createAuthorization()
    },
    async createAuthorization (e) {
      this.passwordFormError = false
      if (this.showCaptcha && this.recaptcha === null) {
        this.showCaptchaFeedback = true
        return
      }
      const body = { username: this.email, password: this.password }

      try {
        await this.$store.dispatch('CREATE_AUTHORIZATION', {
          body,
          captcha: this.recaptcha
        })
        this.passwordFormError = false
        await this.$store.dispatch('SET_USER_FAIL', 0)
        await this.$store.dispatch('GO_TO_REDIRECT_URL')
      } catch (err) {
        this.loginFormIsLoading = false
        this.loginFormError = true
        if (this.recaptcha !== null) this.resetCaptcha()
        this.recaptcha = null
        this.autoclose = true
        const newTotalUserFail = this.totalUserFail + 1
        this.$store.dispatch('SET_USER_FAIL', newTotalUserFail)
        this.errors = formatErrorMessage(err, this.$t('LOGIN.GENERIC-ERROR'))
      }
    },
    emailIsValid () {
      this.email = this.email.toLowerCase()
      this.emailFormIsValid = this.$refs.loginForm.checkValidity()
    },
    passwordIsValid (e) {
      this.passwordValidityState.valid = e.target.validity.valid
      this.passwordValidityState.tooShort = e.target.validity.tooShort
      this.passwordFormIsValid = this.$refs.loginForm.checkValidity()
    },
    hideAlert () {
      this.loginFormError = false
    },
    componentIsEnabled (section, component) {
      return this.$store.getters.componentIsEnabled(section, component)
    },
    resetCaptcha () {
      this.showCaptchaFeedback = false
      this.validatedCaptcha = false
      window.grecaptcha.reset()
    },
    validateCaptcha () {
      this.showCaptchaFeedback = false
      this.validatedCaptcha = true
      this.recaptcha = window.grecaptcha.getResponse()
    },
    showPassword (input) {
      if (input.type === 'password') {
        input.type = 'text'
        this.passwordIsText = true
      } else {
        input.type = 'password'
        this.passwordIsText = false
      }
    }
  }
}
</script>
<style>
#signin-box-login {
  width: 450px;
}

@media screen and (max-width: 500px) {
  #signin-box-login {
    width: auto;
  }
}

.captcha-container > div {
  overflow: hidden;
  margin: 0 auto;
}
</style>
