import Cookies from 'js-cookie'
import { IDInstance, env } from '@/support'

const captcha = {
  state: {
    totalUserFail: Cookies.get(`${env}mp_fl`)
      ? parseInt(
          atob(Cookies.get(`${env}mp_fl`), { domain: IDInstance.domain })
        )
      : 0,
    showCaptcha: false
  },
  getters: {
    totalUserFail (state) {
      return state.totalUserFail
    },
    showCaptcha (state) {
      return state.totalUserFail >= 2 || state.showCaptcha
    }
  },
  mutations: {
    setUserFail (state, value) {
      state.totalUserFail = value
      Cookies.set(`${env}mp_fl`, window.btoa(value), { domain: state.domain })
    },
    setShowCaptcha (state, value) {
      state.showCaptcha = value
    }
  },
  actions: {
    SET_USER_FAIL ({ commit }, value) {
      commit('setUserFail', value)
    },
    SET_SHOW_CAPTCHA ({ commit }, value) {
      commit('setShowCaptcha', value)
    }
  }
}

export default captcha
