const whitelabel = {
  state: {
    enabled: false,
    theme: 'pagarme',
    url: process.env.VUE_APP_BASE_URL,
    domain: '.mundipagg.com'
  },
  getters: {
    whitelabel (state) {
      return state
    }
  },
  mutations: {
    updateWhitelabel (state, value) {
      state = value
    }
  },
  actions: {
    updateWhitelabel ({ commit }, value) {
      commit('updateWhitelabel', value)
    },
    loadEmeraldByTheme ({ commit }, theme) {
      const emerald = () =>
        import(`emerald-workbench/dist/emerald.${theme}.min.css`)
      commit('updateWhitelabel', { theme: theme })
      emerald()
    }
  }
}
export default whitelabel
