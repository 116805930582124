<template>
  <div
    v-if="!isIE"
    id="app"
  >
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  computed: {
    appVersion () {
      return this.$store.state?.application?.appVersion
    },
    isIE () {
      return window.isIE
    }
  },
  mounted () {
    var meta = document.createElement('meta')
    meta.name = 'version'
    meta.content = this.appVersion
    document.getElementsByTagName('head')[0].appendChild(meta)
  },
  beforeCreate () {
    if (['pagarme'].includes(process.env.VUE_APP_THEME))
      this.$store.dispatch('updateWhitelabel', {
        enabled: true,
        theme: process.env.VUE_APP_THEME
      })
  }
}
</script>

<style>
#atlwdg-trigger {
  background: var(--brand--le--primary-color--base);
  color: #fff !important;
  border: none;
  box-shadow: none;
  border-radius: 4px 0 0 0;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 11px;
  padding: 6px 12px;
}
</style>
