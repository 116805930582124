const components = {
  logo: {
    mundipagg: ['mundipagg'],
    pagarme: ['pagarme'],
    jpmorgan: ['jpmorgan']
  },
  globalLoader: {
    mundipagg: ['mundipagg', 'jpmorgan'],
    pagarme: ['pagarme']
  },
  globalError: {
    logo: ['mundipagg', 'pagarme']
  },
  input: {
    help: ['jpmorgan']
  },
  footer: {
    switchThemeMode: ['mundipagg'],
    switchLanguage: ['mundipagg', 'jpmorgan'],
    help: ['mundipagg', 'pagarme'],
    privacySecurity: ['jpmorgan'],
    signup: ['mundipagg'],
    autocred: ['pagarme'],
    privacyPolicy: ['mundipagg', 'pagarme'],
    termsOfUse: ['mundipagg', 'pagarme'],
    termsOfUseModal: ['mundipagg']
  }
}

export default components
